<template>
	<el-card class="box-card">
		<div slot="header" class="tit">
			<h3 class="inBlock">添加商品</h3>
			<back></back>
		</div>
		<div class="box">
			<!-- 虚拟实体门店-->
			<div class="fenlei">
				<div class="label">门店类型</div>
				<div class="content">
					<el-form :model="from" :rules="rules" ref="stimefrom" label-width="100px" class="demo-ruleForm">
						<el-form-item label="商品可显示门店类型" label-width="150px">
							<el-radio v-model="from.goods_store_type" :label="1">全部门店</el-radio>
							<el-radio v-model="from.goods_store_type" :label="2">实体门店</el-radio>
							<el-radio v-model="from.goods_store_type" :label="3" @change="shopSelect">虚拟门店</el-radio>
						</el-form-item>
					</el-form>
				</div>
			</div>
			
			<!-- 邮寄方式 -->
			<div class="fenlei">
				<div class="label">邮寄方式</div>
				<div class="content">
					<el-form :model="from" :rules="rules" ref="stimefrom" label-width="100px" class="demo-ruleForm">
						<el-form-item label="邮寄方式">
							<el-radio v-model="from.choise_type" :label="1">自提</el-radio>
							<el-radio v-model="from.choise_type" :label="2">邮寄</el-radio>
							<el-radio v-model="from.choise_type" :label="3">自提和邮寄</el-radio>
						</el-form-item>
					</el-form>
				</div>
			</div>

			<!-- 邮寄方式 -->
			<div class="fenlei">
				<div class="label">商品类型</div>
				<div class="content">
					<el-form :model="from" :rules="rules" ref="stimefrom" label-width="100px" class="demo-ruleForm">
						<el-form-item label="商品类型">
							<el-radio v-model="from.goods_type" :label="1" @change="goodsSelect">实物商品</el-radio>
							<el-radio v-model="from.goods_type" :label="2">虚拟商品</el-radio>
						</el-form-item>
					</el-form>
				</div>
			</div>

			<!-- 自提盈润比例 -->
			<div class="fenlei">
				<div class="label">自提盈润比例</div>
				<div class="content">
					<el-form :model="from" :rules="rules" ref="stimefrom" label-width="100px" class="demo-ruleForm">
						<el-form-item label="自提盈润比例">
							<input type="text" v-model="from.offline_share">　%（仅限虚拟商品使用）
						</el-form-item>
					</el-form>
				</div>
			</div>

			<!-- 自提盈润比例 -->
			<div class="fenlei">
				<div class="label">邮寄盈润比例</div>
				<div class="content">
					<el-form :model="from" :rules="rules" ref="stimefrom" label-width="100px" class="demo-ruleForm">
						<el-form-item label="邮寄盈润比例">
							<input type="text" v-model="from.mail_share">　%（实体商品通用）
						</el-form-item>
					</el-form>
				</div>
			</div>
			
			<!-- 选择替换商品 -->
			<div class="fenlei" v-if="from.goods_type == 2">
				<div class="label" style="padding-top: 28px">替换商品</div>
				<div class="content">
					<el-button plain size="small" @click="chooseProduct">点击选择</el-button>
					<span style="color: red;margin-left: 20px;font-weight: 600;">实物商品请勿关联其它商品（涉及分润，务必谨慎）！！！</span>
					<div class="selectedProList">
						<div
						class="selectedProItem"
						v-for="(item2, index2) in selectProList"
						:key="index2"
						>
						<img :src="imgurl + item2.cover_pic" alt="" srcset="" />
						<div class="probottom">
							<p>{{ item2.name }}</p>
							<span>{{ item2.price }}</span>
						</div>
						</div>
					</div>
				</div>
			</div>
			<div class="fenlei">
				<div class="label">选择分类</div>
				<div class="content">
					<el-form :model="from" :rules="rules" ref="classfrom" label-width="100px" class="demo-ruleForm">
						<el-form-item label="商品分类" prop="cat_id">
							<el-input :disabled="true" v-model="cat_name" placeholder="点击右侧下拉菜单选择分类"></el-input>
							<el-cascader style="margin-left: 20px" :props="optionProps" :options="classAll"
								@change="setclass2" ref="cascader"></el-cascader>
							<!-- <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree> -->
						</el-form-item>
					</el-form>
				</div>
			</div>

			<!-- 基本信息 -->
			<div class="fenlei">
				<div class="label">基本信息</div>
				<div class="content">
					<el-form :model="from" :rules="rules" ref="detailfrom" label-width="100px" class="demo-ruleForm">
						<el-form-item label="商品名称" prop="name">
							<el-input v-model="from.name" placeholder="请输入商品名称"></el-input>
						</el-form-item>
						<el-form-item label="商品排序">
							<el-input v-model="from.sort" placeholder="请输入商品序号"></el-input>
						</el-form-item>
						<el-form-item label="商品状态">
							<div style="display: inline; margin-right: 100px; padding-left: 10px">
								<el-radio v-model="from.status" :label="1">上架</el-radio>
								<el-radio v-model="from.status" :label="0">下架</el-radio>
							</div>
						</el-form-item>
						<el-form-item label="单位">
							<el-input v-model="from.unit"></el-input>
						</el-form-item>
						<el-form-item label="虚拟销量">
							<el-input v-model="from.virtual_sales"></el-input>
						</el-form-item>
						<el-form-item label="商品重量" prop="weight">
							<el-input v-model="from.weight">
								<template slot="append">KG</template>
							</el-input>
						</el-form-item>
						<el-form-item label="商品缩略图">
							<el-input v-model="from.cover_pic" :disabled="true" placeholder="缩略图路径"></el-input>
							<span class="uphint">商品列表页图片, 注意尺寸大小350*350</span>
							<up-img v-if="from.cover_pic == ''" @addimg="upMinimg" :type="'shop'"></up-img>
							<div v-if="from.cover_pic != ''" class="image">
								<img :src="imgurl+from.cover_pic" alt="" />
								<span class="shou" @click="delMinimg">×</span>
							</div>
						</el-form-item>

						<el-form-item label="商品图片或视频">
							<el-input :disabled="true" placeholder="请上传商品图片或视频">
							</el-input>
							<span class="uphint">商品详情页面轮播图!780*780</span>
							<div class="imagelist">
								<template v-if="from.goods_pic_list.length >= 1">
							 <!-- 拖拽交换位置效果 -->
									<transition-group tag="div" class="container">
										<!-- 循环出三个颜色不同的div -->
										<div v-for="item in from.goods_pic_list" :key="item.key" class="image"
											draggable="true"
											@dragstart="handleDragStart($event, item)"
											@dragover.prevent="handleDragOver($event, item)"
											@dragenter="handleDragEnter($event, item)"
											@dragend="handleDragEnd($event, item)" >
										<img v-if="item.pic.split('.')[item.pic.split('.').length-1]!='mp4'" :src="imgurl+item.pic"
											alt="" />
										<video style="height: 100%;" v-else :src="imgurl+item.pic" alt="" />
										<span class="shou" @click="delImgList(item.key)">×</span>
									</div>
								</transition-group>
									
								</template>
								<up-img v-if="from.goods_pic_list.length < 5" @addimg="upImgList" :type="'shop'">
								</up-img>
							</div>
						</el-form-item>
						<!-- <el-form-item label="拖拽">
							<transition-group tag="div" class="container">
								<div class="item" v-for="res in items" :key="res.key" 
								:style="{background:res.color,width:'80px',height:'80px'}"
									draggable="true"
									@dragstart="handleDragStart($event, res)"
									@dragover.prevent="handleDragOver($event, res)"
									@dragenter="handleDragEnter($event, res)"
									@dragend="handleDragEnd($event, res)" >
								</div>
						</transition-group> -->

						<!-- </el-form-item> -->
						<el-form-item label="市场价" prop="original_price">
							<el-input v-model="from.original_price">
								<template slot="append">元</template>
							</el-input>
						</el-form-item>
						<el-form-item label="售价" prop="price">
							<el-input v-model="from.price">
								<template slot="append">元</template>
							</el-input>
						</el-form-item>
						<el-form-item label="会员价" prop="cost_price">
							<el-input v-model="from.cost_price">
								<template slot="append">元</template>
							</el-input>
						</el-form-item>
						<el-form-item label="直播价" prop="live_price">
							<el-input v-model="from.live_price">
								<template slot="append">元</template>
							</el-input>
						</el-form-item>
						<el-form-item label="成本价" prop="purchase_price">
							<el-input v-model="from.purchase_price">
								<template slot="append">元</template>
							</el-input>
						</el-form-item>
						<el-form-item label="起拍数量" prop="start_num">
							<el-input v-model="from.start_num">
								<template slot="append">件</template>
							</el-input>
						</el-form-item>
						<el-form-item label="会员权益">
							<el-radio v-model="from.is_vipgift" :label="1">是</el-radio>
							<el-radio v-model="from.is_vipgift" :label="2">否</el-radio>
						</el-form-item>

					</el-form>
				</div>
			</div>
			<!-- 规格库存 -->
			<div class="fenlei">
				<div class="label">商品编码</div>
				<div class="content">
					<el-form :model="from" :rules="rules" ref="repertoryfrom" label-width="100px" class="demo-ruleForm">
						<el-form-item label="商品库存" prop="goods_num">
							<el-input v-model="from.goods_num">
								<template slot="append">件</template>
							</el-input>
						</el-form-item>
						<!-- <el-form-item label="库存预警数">
							<el-input v-model="from.goods_warning_num">
								<template slot="append">件</template>
							</el-input>
						</el-form-item> -->
						<el-form-item label="商品编码" prop="goods_no">
							<el-input v-model="from.goods_no"> </el-input>
						</el-form-item>
					</el-form>
				</div>
			</div>

			<!-- 物流信息 -->
			<!-- <div class="fenlei">
				<div class="label">物流模板</div>
				<div class="content">
					<el-select v-model="from.express_id" placeholder="请选择">
						<el-option
							v-for="item in wlTemplateList"
							:key="item.id"
							:label="item.name"
							:value="item.id">
						</el-option>
					</el-select>
				</div>
			</div> -->

			<!-- 分佣 -->
			<!-- <div class="fenlei">
				<div class="label">佣金</div>
				<div class="content">
					<el-form :model="from" :rules="rules" ref="stimefrom" label-width="100px" class="demo-ruleForm">
						<el-form-item label="分佣">
							<el-radio v-model="from.is_agent_share" :label="1">分佣</el-radio>
							<el-radio v-model="from.is_agent_share" :label="0">不分佣</el-radio>
						</el-form-item>
						<el-form-item v-if="from.is_agent_share == 1" label="分佣金额">
							<input @input="shuru" class="fenyong" v-model="from.agent_share_price" type="text"
								placeholder="请输入分佣金额" />
						</el-form-item>
					</el-form>
				</div>
			</div> -->

			<!-- 分佣得商品 -->
			<div class="fenlei">
				<div class="label">分佣赠品</div>
				<div class="content">
					<el-form :model="from" :rules="rules" ref="stimefrom" label-width="100px" class="demo-ruleForm">
						<el-form-item label="分佣">
							<el-radio v-model="from.is_agent_share" :label="1">分佣</el-radio>
							<el-radio v-model="from.is_agent_share" :label="0">不分佣</el-radio>
						</el-form-item>
						<el-form-item v-if="from.is_agent_share == 1" label="购买数量">
							<el-input v-model="from.full_goods_num"> </el-input>
						</el-form-item>
						<el-form-item v-if="from.is_agent_share == 1" label="赠送数量">
							<el-input v-model="from.send_goods_num"> </el-input>
						</el-form-item>
					</el-form>
				</div>
			</div>
			<!-- 是否0.1元抽奖商品 -->
			<div class="fenlei">
				<div class="label">是否是九宫格抽奖商品</div>
				<div class="content">
					<el-form :model="from" :rules="rules" ref="stimefrom" label-width="100px" class="demo-ruleForm">
						<el-form-item label="商品类型">
							<el-radio v-model="from.goods_lottery" :label="1">是</el-radio>
							<el-radio v-model="from.goods_lottery" :label="2">否</el-radio>
						</el-form-item>
					</el-form>
				</div>
			</div>
			<!-- 商品限购 -->
			<div class="fenlei">
				<div class="label">限时购</div>
				<div class="content">
					<el-form :model="from" :rules="rules" ref="stimefrom" label-width="100px" class="demo-ruleForm">
						<el-form-item label="是否开启">
							<el-radio v-model="from.restrict_status" :label="1">开启</el-radio>
							<el-radio v-model="from.restrict_status" :label="2">关闭</el-radio>
						</el-form-item>
						<el-form-item v-if="from.restrict_status == 1" label="限购数量">
							<el-input v-model="from.restrict_num"> </el-input>
						</el-form-item>
						<el-form-item v-if="from.restrict_status == 1" label="限购时间">
							<el-date-picker
								v-model="TimeValue"
								type="datetimerange"
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								align="left"
								size="small"
								value-format="yyyy-MM-dd HH:mm"
							>
							</el-date-picker>
						</el-form-item>
					</el-form>
				</div>
			</div>

			<!-- 图文详情 -->
			<div class="fenlei">
				<div class="label">图文详情</div>
				<div class="content">
					<el-form :model="from" :rules="rules" ref="stimefrom" label-width="100px" class="demo-ruleForm">
						<el-form-item label="图文详情">
							<div id="editor" style="width: 600px; height: 500px"></div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<div class="smt">
			<span class="shou" @click="submit">保存</span>
			<span class="shou">返回</span>
		</div>
		<!-- 选择商品列表 -->
    <el-dialog title="选择商品" :visible.sync="chooseprodialog" width="30%" style="padding-top: 0px">
      <div class="productList">
         <div class="tiaojian">
            <div class="left">
              <strong>商品名称: </strong
              ><el-input
                v-model="goodsForm.name"
                placeholder="请输入商品名称"
              ></el-input>
            </div>
          <span class="shaixuan shou" @click="filterListModel">筛选</span>
        </div>
        <el-table
          row-key="id"
          ref="multipleTable"
          :data="liveProductList"
          tooltip-effect="dark"
          style="width: 100%;padding-top: 0px;"
        >
        <el-table-column label="操作">
            <template slot-scope="scope">
            <el-button
                @click="selectShop(scope.row)"
                type="text"
                size="small"
                >选择</el-button
            >
            </template>
        </el-table-column>
          <el-table-column label="商品ID" width="120">
            <template slot-scope="scope">{{ scope.row.id }}</template>
          </el-table-column>
          <el-table-column prop="name" label="商品名称" width="200">
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="商品价格"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.price }}</template>
          </el-table-column>
          
        </el-table>
         <el-pagination
          background
          style="margin-top: 20px"
          @current-change="crtChg"
          :page-size="goodsForm.limit"
          :current-page="goodsForm.page"
          layout="total, prev, pager, next"
          :total="modelTotal"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="chooseProSele">确 定</el-button>
      </span>
    </el-dialog>
	</el-card>
</template>

<script>
	import {
		shopclass,
		updataImg,
		addgoods,
		goodsdetail,
		catdetail,
		goodsChoiceGoods,
		getcatAll,
		getExpressTemplate
	} from "@/api/api.js";
	import {getSystemsettings} from '../../api/getSystemsetting.js'
	import UpImg from "@/components/upImg.vue";
	import E from "wangeditor";
	export default {
		name: "addGoods",
		components: {
			UpImg
		},
		data() {
			return {
				editor: null,
				brokerage: false,
				classAll: [],
				cat_name: "",
				editor: '',
				chooseprodialog: false, //选择关联商品弹窗
				goodsForm: {
					limit: 7,
					page: 1,
					id: '',
					name: ''
				},
				modelTotal: 0, //关联商品总条数
				liveProductList: [],
      			selectProList: [], //选中商品

				items: [
					{ key: 1, color: '#ffebcc'},
					{ key: 2, color: '#ffb86c'},
					{ key: 3, color: '#f01b2d'}
				],
						
				dragging: null,



				from: {
					cat_id: "", // 分类id s
					name: "", // 商品名字 s
					unit: "", //单位 s
					sort: "", // 排序 s
					price: "", // 售价 s
					cost_price: "", // 会员价
					live_price: "",
					original_price: "", // 市场价 s
					purchase_price:"",    //  成本价
					detail: "", //商品详情 s
					status: 0, // 上下架状态 s
					goods_num: "", // 商品库存数量 s
					// goods_warning_num: "", // 商品预警数量 s
					attr: "", // 多规格
					use_attr: 0, // 是否使用多规格
					goods_pic_list: [], // 图片数据 s
					goods_no: "", // 商品货号 s
					is_agent_share: 0, // 是否加入分佣 s
					agent_share_price: "", // 分佣金额 s
					virtual_sales: "", // 虚拟销量
					cover_pic: "", // 缩略图
					is_vipgift: 2,  //是否设为会员权益商品
					full_goods_num:"",  // 用户购买商品数量
					send_goods_num:"" ,  // 赠送推荐人商品数量
					restrict_status: 1,   // 限购状态：1.开启，2.关闭
					restrict_num:"",   // 限购数量
					start_num:"",     // 起拍数量
					restrict_start_time:"",   // 限购开始时间
					restrict_end_time:"",  // 限购结束时间
					id:"",   // 修改商品ID
					// express_id: "",   // 物流模板ID
					weight: "",  // 商品重量
					choise_type: 3, //提货方式
					goods_type: 1, //1实物商品 2虚拟商品
					goods_lottery: 2, //是否是九宫格抽奖商品
					goods_store_type : 2, //商品门店分类  1全部 2入驻门店 3虚拟门店
					relation_goods_id: '', //替换商品id
					mail_share: 0, //邮寄盈润比例
					offline_share: 0, //自提盈润比例
 				},
				imgurl:'',
				rules: {
					cat_id: [{
							required: true,
							message: "请选择商品分类",
							trigger: "change"
						},
						// { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
					],
					name: [{
						required: true,
						message: "请输入商品名称",
						trigger: "blur"
					}],
					original_price: [{
						required: true,
						message: "请填写市场价",
						trigger: "blur"
					}, ],
					price: [{
						required: true,
						message: "请填写售价",
						trigger: "blur"
					}],
					cost_price: [{
						required: true,
						message: "请填写会员价",
						trigger: "blur"
					}, ],
					live_price: [{
						required: true,
						message: "请填写直播价",
						trigger: "blur"
					}],
					purchase_price: [{
						required: true,
						message: "请填写成本价",
						trigger: "blur"
					}],
					goods_num: [{
						required: true,
						message: "请输入商品库存",
						trigger: "blur"
					}, ],
					goods_no: [{
						required: true,
						message: "请填写商品货号",
						trigger: "blur"
					}, ],
					weight: [{ required: true, message: "请填写商品重量", trigger: "blur"}]
				},
				optionProps: {
					value: "id",
					label: "name",
					children: "childer",
				},
				isShowDailog:false,
				TimeValue: null,
				wlTemplateList:[],
				value: ''
			};
		},
		watch:{
			TimeValue: function(newVal, oldVal) {
				console.log(newVal,oldVal)
				if (newVal != null) {
					this.from.restrict_start_time = this.$timeTo.time4(this.TimeValue[0]);
					this.from.restrict_end_time = this.$timeTo.time4(this.TimeValue[1]);
				}else{
					this.from.restrict_start_time = '';
					this.from.restrict_end_time = '';
				}
			},
		},
		methods: {
			shopSelect(){
				this.from.choise_type = 2
			},
			// 实物商品选择
			goodsSelect(){
				this.selectProList = []
				this.from.relation_goods_id = ''
			},
			shuru(e) {
				// <el-form-item label="市场价" prop="original_price">
				//   <el-input v-model="from.original_price">
				//     <template slot="append">元</template>
				//   </el-input>
				// </el-form-item>
				// <el-form-item label="售价" prop="price">
				//   <el-input v-model="from.price">
				//     <template slot="append">元</template>
				//   </el-input>
				// </el-form-item>
				// <el-form-item label="会员价" prop="cost_price">
				//   <el-input v-model="from.cost_price">
				//     <template slot="append">元</template>
				//   </el-input>
				// </el-form-item>
				// <el-form-item label="直播价" prop="live_price">
				//   <el-input v-model="from.live_price">
				//     <template slot="append">元</template>
				//   </el-input>
				// </el-form-item>
				// console.log(this.from.price,this.from.original_price,this.from.live_price,this.from.agent_share_price)
				var agent_share_price = parseInt(this.from.agent_share_price)
				var price = parseInt(this.from.price) //售价
				var original_price = parseInt(this.from.original_price) //市场价
				var live_price = parseInt(this.from.live_price) //直播价
				var cost_price = parseInt(this.from.cost_price) //会员价
				if (agent_share_price > price || agent_share_price > original_price || agent_share_price > live_price ||
					agent_share_price > cost_price) {
					this.from.agent_share_price = 0
					return this.$message.error('分佣不能大于售价，市场价，直播价，会员价');
				}
			},
			// 选择关联商品
			selectShop(row){
				this.selectProList = []
				this.selectProList.push(row)
				this.from.relation_goods_id = row.id
				// this.form.name = row.name
				// this.form.pic = row.cover_pic
				// this.form.price = row.price
				console.log(row)
			},
			 // 选择商品按钮
			chooseProduct() {
				this.chooseprodialog = true;
				this.getProductlist();
			},
			// 获取奖品商品列表
			async getProductlist() {
				this.goodsForm.name = ''
				const { data } = await goodsChoiceGoods(this.goodsForm);
				if (data.code != 200) return this.$message.error(data.data);
				console.log(data)
				this.liveProductList = data.data.data;
				this.modelTotal = data.data.total;
			},
			// 获取商品列表搜索
			async filterListModel(){
				this.goodsForm.page = 1
				const { data } = await goodsChoiceGoods(this.goodsForm);
				if (data.code != 200) return this.$message.error(data.data);
				console.log(data)
				this.liveProductList = data.data.data;
				this.modelTotal = data.data.total;
				this.goodsForm.name = ''
			},
			// 商品列表确定
			chooseProSele() {
				this.chooseprodialog = false;
			},
			// 组件切换分页
			crtChg(e) {
				this.goodsForm.page = e;
				this.getProductlist();
			},
			delMinimg() {
				this.from.cover_pic = "";
			},
			upMinimg(e) {
				this.from.cover_pic = e;
			},
			delImgList(index) {
				this.from.goods_pic_list.splice(index, 1);
			},
			upImgList(imgurl) {
				// console.log(imgurl.split('.')[imgurl.split('.').length-1])
				console.log(imgurl)
				if (imgurl.play_url) {
					let pic_list = {
						key: this.from.goods_pic_list.length,
						pic: imgurl.play_url
					}
					this.from.goods_pic_list.push(pic_list);
					// this.from.goods_pic_list.push(imgurl.play_url);
				} else {
					let pic_list = {
						key: this.from.goods_pic_list.length,
						pic: imgurl
					}
					this.from.goods_pic_list.push(pic_list);
					// this.from.goods_pic_list.push(imgurl);
				}

				console.log(this.from.goods_pic_list)
			},
			setclass2(value) {
				let nodesObj = this.$refs["cascader"].getCheckedNodes();
				this.from.cat_id = nodesObj[0].data.id;
				this.cat_name = nodesObj[0].data.name;
			},
			initEditor() {
				this.editor.config.placeholder = "请输入内容";
				this.editor.config.onchangeTimeout = 0;
				this.editor.config.uploadImgShowBase64 = true;
				this.editor.config.uploadImgMaxSize = 10 * 1024 * 1024; // 将图片大小限制为 3M
				this.editor.config.customUploadImg = async (files, insert) => {
					const {
						data
					} = await updataImg(files[0], "shop");
					if (data.code != 200) return false;
					this.editor.txt.append(`<img src="${data.data.url}" alt="">`);
				};
				// 配置 onchange 回调函数，将数据同步到 vue 中
				this.editor.config.onchange = (res) => {
					this.from.detail = res;
				};
				// 创建编辑器
				this.editor.create();
			},
			submit() {
				if (this.from.cover_pic == "" || this.from.goods_pic_list.length < 1) 
					return this.$message.error("请选择商品图片");
				this.$refs["classfrom"].validate((valid) => {
					if (valid) {
						this.$refs["detailfrom"].validate((valid) => {
							if (valid) {
								this.$refs["repertoryfrom"].validate(async (valid) => {
									if (valid) {
										// 实物商品不关联替换商品
										if(this.from.goods_type == 1){
											this.from.relation_goods_id = ''
										}
										console.log('valid.id====',valid)
										// this.from.express_id = 0;
										// this.from.id = this.value;
										// this.from.id = this.list.id
										let pic = []
										this.from.goods_pic_list.forEach(item => {
											pic.push(item.pic)
										})
										this.from.goods_pic_list = pic
										const {
											data
										} = await addgoods(this.from);
										if (data.code != 200){
											pic.forEach((item, index) => {
												let pic_list = {
													key: index,
													pic: item
												}
												this.from.goods_pic_list.push(pic_list);
											})
											 return this.$message.error(data.data);
										}
										
										if (this.$route.query.id) {
											this.$message.success("修改商品成功");
											this.$router.back()
										} else {
											this.$message.success("添加商品成功");
											this.$router.go(0);
										}
									} else {
										this.$message.error("保存失败! 请检查填写的内容!");
										return false;
									}
								});
							} else {
								this.$message.error("保存失败! 请检查填写的内容!");
								return false;
							}
						});
					} else {
						this.$message.error("保存失败! 请检查填写的内容!");
						return false;
					}
				});
			},
			async getclass() {
				const {
					data
				} = await getcatAll();
				this.classAll = data.data.cat_list;
			},
			async getdetail(id) {
				const {
					data
				} = await goodsdetail(id);
				if (data.code != 200) return this.$message.error(data.data);
				let list = data.data.detail_goods;
				this.from.id = list.id;
				this.from.name = list.name;
				this.from.unit = list.unit;
				this.from.sort = list.sort;
				this.from.price = list.price;
				this.from.cost_price = list.cost_price;
				this.from.live_price = list.live_price;
				this.from.purchase_price = list.purchase_price;
				this.from.original_price = list.original_price;
				this.from.detail = list.detail;
				this.from.status = list.status;
				this.from.goods_num = list.goods_num;
				this.from.goods_warning_num = list.goods_warning_num;
				this.from.attr = list.attr;
				this.from.use_attr = list.use_attr;
				this.from.goods_no = list.goods_no;
				this.from.weight = list.weight;
				this.from.is_agent_share = list.is_agent_share;
				this.from.agent_share_price = list.agent_share_price;
				this.from.virtual_sales = list.virtual_sales;
				this.from.cover_pic = list.cover_pic;
				this.from.cat_id = list.cat_id;
				this.from.is_vipgift = list.is_vipgift;
				this.from.full_goods_num = list.full_goods_num;
				this.from.send_goods_num = list.send_goods_num;
				this.from.choise_type = list.choise_type;
				this.from.goods_type = list.goods_type;
				this.from.goods_lottery = list.goods_lottery;
				this.from.goods_store_type = list.goods_store_type;
				this.from.offline_share = list.offline_share;
				this.from.mail_share = list.mail_share;
				this.from.restrict_num = list.restrict_num;
				this.from.start_num = list.start_num;
				this.from.restrict_status = list.restrict_status;
				this.from.relation_goods_id = list.relation_goods_id
				this.selectProList = [] //关联商品置空
				if(list.relation_goods_id){
					this.selectProList.push(list.relation_goods)
					console.log( this.selectProList)
				}
				this.from.restrict_start_time = this.$timeTo.time3(list.restrict_start_time);
				this.from.restrict_end_time = this.$timeTo.time3(list.restrict_end_time);
				let dateTime = []
				dateTime.push(this.from.restrict_start_time)
				dateTime.push(this.from.restrict_end_time)
				this.TimeValue = dateTime
				console.log(list.restrict_start_time,list.restrict_start_time)
				console.log('this.from.restrict_start_time=======',this.TimeValue)
				list.goods_pic.forEach((item, index) => {
					let pic_list = {
						key: index,
						pic: item.pic_url
					}
					this.from.goods_pic_list.push(pic_list);
				});
				this.classAll.forEach((item) => {
					item.childer.forEach((item2) => {
						if (item2.id == list.cat_id) {
							this.cat_name = item2.name;
						}
					});
				});
				this.editor.txt.html(list.detail);
			},

			// 图片拖拽
			 handleDragStart(e,item){
				this.dragging = item;
			},
			handleDragEnd(e,item){
				this.dragging = null
			},
			//首先把div变成可以放置的元素，即重写dragenter/dragover
			// DataTransfer 对象用来保存，通过拖放动作，拖动到浏览器的数据。
			// 如果dropEffect 属性设定为none,则不允许被拖放到目标元素中。
			handleDragOver(e) {
				e.dataTransfer.dropEffect = 'move'// e.dataTransfer.dropEffect="move";//在dragenter中针对放置目标来设置!
			},
			handleDragEnter(e,item){
				e.dataTransfer.effectAllowed = "move"//为需要移动的元素设置dragstart事件
				if(item === this.dragging){
				return
				}
				const newItems = [...this.from.goods_pic_list]
				console.log(newItems)
				const src = newItems.indexOf(this.dragging)
				const dst = newItems.indexOf(item)
				// 替换
				newItems.splice(dst, 0, ...newItems.splice(src, 1))
				// 让item的颜色等于新交换的颜色
				this.from.goods_pic_list = newItems
			}


			// // 获取快递模板列表
			// async getExpressTemplate() {
			// 	const { data } = await getExpressTemplate();
			// 	if (data.code != 200) return this.$message.error(data.data);
			// 	this.wlTemplateList = data.data.data;
			// 	console.log(this.wlTemplateList);
			// },

		},
		created() {
			this.getclass();
			// this.getExpressTemplate();
				console.log('this.$route.query.id====',this.$route.query.id)
			if (this.$route.query.id) {
				console.log('this.$route.query.id====',this.$route.query.id)
				this.getdetail({
					id: this.$route.query.id
				});
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.editor = new E("#editor")
				this.initEditor();
			})
			getSystemsettings.then(res=>{
				console.log(res)
				this.imgurl=res
			})
		},
		beforeDestroy() {
			this.editor.destroy();
			this.editor = null;
		},
	};
</script>

<style scoped lang='less'>
	.fenyong {
		height: 30px;
		border: 1px solid #c0c4cc;
		border-radius: 2px;
	}

	.smt {
		padding: 12px 25px;
		border: 1px solid #eee;
		margin-block: 15px;

		span {
			background-color: #e0e0e0;
			color: #333;
			margin-left: 15px;
			padding: 5px 15px;
			border-radius: 3px;

			&:nth-child(1) {
				background-color: #53cbff;
				color: #fff;

				&:hover {
					background-color: #35d6b6;
				}
			}
		}
	}

	.el-form-item__label {
		color: #333;
	}

	.fenlei {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;

		.label {
			flex: 1;
			border: 1px solid #eee;
			text-align: center;
			padding-top: 40px;
			font-weight: 900;
			font-size: 16px;
			margin-right: 10px;
		}

		.content {
			flex: 8;
			border: 1px solid #eeeeee;
			padding: 20px;
		}

		.el-form-item {
			.imagelist {
				display: flex;
			}

			.el-radio {
				margin-left: 30px;

				&:last-child {
					margin-left: 0px;
				}
			}

			

			.upimg {
				border: 1px solid #777;
				font-size: 20px;
				padding: 1px 12px;
				margin: 1px 0 0 20px;
				color: #777;

				&:last-child {
					margin: 10px;
				}
			}

			position: relative;

			.dingwei {
				position: absolute;
				bottom: -26px;
				font-size: 12px;
				color: #777;
			}

			.el-input {
				.append {
					padding: 0;
					display: flex;
					justify-content: space-between;

					i {
						display: block;

						&:nth-child(2) {
							background-color: pink;
						}
					}
				}

				width: 500px;

				/deep/.el-input__inner {
					height: 34px;
					color: #333;
				}
			}

			.el-dropdown {
				span {
					border: 1px solid #888;
					padding: 6px 10px;
					margin-left: 30px;
				}
			}
		}
	}

	.uphint {
		font-size: 13px;
		color: #53cbff;
		margin-left: 5px;
	}

	/deep/.w-e-text {
		height: 400px;
	}

	/deep/.el-input-group__append {
		background-color: #fff;
	}

	/deep/.videoTxt {
		line-height: 30px;
		position: relative;
		color: #22a1ff;
		font-size: 14px;

		.dw {
			position: absolute;
			top: 15px;
			color: #fe3f14;
			font-size: 12px;
		}
	}

	/deep/.avatar-uploader-icon {
		width: 130px;
		height: 150px;
	}

	/deep/.el-upload {
		margin: 0;
	}

	#editor {
		z-index: 2;
	}
	/deep/.el-dialog__body{
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}
.selectedProList {
	display: flex;
	flex-wrap: wrap;
.selectedProItem {
	width: 100px;
	height: 175px;
	border: 1px solid #efefef;
	margin-right: 10px;
	img {
	width: 98px;
	height: 98px;
	}
	.probottom {
	padding: 6px;
	p {
		line-height: 20px;
		font-size: 12px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 86px;
	}
	span {
		color: #ff4544;
	}
	}
}
}

.tiaojian {
  margin: 20px 0;
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: #edeff0;
  position: relative;
  .left {
    strong {
      font-weight: normal;
      font-size: 14px;
      color: #606266;
      padding-top: 10px;
      margin-right: 2px;
    }
    .el-input {
      width: 300px;
      margin-right: 20px;
      /deep/.el-input__inner {
        height: 30px;
      }
    }
    .checkboxBox {
      padding-top: 10px;
      margin: 0 0 20px 0;
    }
  }
  .shaixuan {
    background-color: #18bdff;
    color: aliceblue;
    padding: 3px 10px;
    border-radius: 2px;
    position: absolute;
    bottom: 15px;
    left: 400px;
    &:hover {
      background-color: #1abe9c;
    }
  }
}
.container{
	display: flex;
}
.image {
	font-size: 12px;
	width: 130px;
	height: 150px;
	color: #aaa;
	border: 1px solid #eeeeee;
	position: relative;
	top: -10px;
	margin: 10px 10px 0 0;

	img {
		width: 130px;
		height: 150px;
	}

	span {
		position: absolute;
		top: 0;
		right: 0;
		width: 20px;
		text-align: center;
		line-height: 20px;
		font-size: 12px;
		height: 20px;
		background-color: #ffd1d1;
		color: #fff3f3;
		z-index: 9;

		&:hover {
			background-color: #ff4544;
			color: #ffffff;
		}
	}
}
</style>
